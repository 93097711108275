exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-capabilities-js": () => import("./../../../src/pages/about/capabilities.js" /* webpackChunkName: "component---src-pages-about-capabilities-js" */),
  "component---src-pages-about-company-profile-js": () => import("./../../../src/pages/about/company-profile.js" /* webpackChunkName: "component---src-pages-about-company-profile-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-plant-js": () => import("./../../../src/pages/about/plant.js" /* webpackChunkName: "component---src-pages-about-plant-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-induction-bending-cojafex-induction-bending-process-js": () => import("./../../../src/pages/induction-bending/cojafex-induction-bending-process.js" /* webpackChunkName: "component---src-pages-induction-bending-cojafex-induction-bending-process-js" */),
  "component---src-pages-induction-bending-index-js": () => import("./../../../src/pages/induction-bending/index.js" /* webpackChunkName: "component---src-pages-induction-bending-index-js" */),
  "component---src-pages-induction-bending-pipe-bends-js": () => import("./../../../src/pages/induction-bending/pipe-bends.js" /* webpackChunkName: "component---src-pages-induction-bending-pipe-bends-js" */),
  "component---src-pages-induction-bending-structural-bends-js": () => import("./../../../src/pages/induction-bending/structural-bends.js" /* webpackChunkName: "component---src-pages-induction-bending-structural-bends-js" */),
  "component---src-pages-materials-carbon-steel-and-x-grade-linepipe-js": () => import("./../../../src/pages/materials/carbon-steel-and-x-grade-linepipe.js" /* webpackChunkName: "component---src-pages-materials-carbon-steel-and-x-grade-linepipe-js" */),
  "component---src-pages-materials-chromoly-pipe-js": () => import("./../../../src/pages/materials/chromoly-pipe.js" /* webpackChunkName: "component---src-pages-materials-chromoly-pipe-js" */),
  "component---src-pages-materials-index-js": () => import("./../../../src/pages/materials/index.js" /* webpackChunkName: "component---src-pages-materials-index-js" */),
  "component---src-pages-materials-nickel-alloys-and-exotics-js": () => import("./../../../src/pages/materials/nickel-alloys-and-exotics.js" /* webpackChunkName: "component---src-pages-materials-nickel-alloys-and-exotics-js" */),
  "component---src-pages-materials-stainless-steel-and-duplex-js": () => import("./../../../src/pages/materials/stainless-steel-and-duplex.js" /* webpackChunkName: "component---src-pages-materials-stainless-steel-and-duplex-js" */),
  "component---src-pages-materials-structural-sections-js": () => import("./../../../src/pages/materials/structural-sections.js" /* webpackChunkName: "component---src-pages-materials-structural-sections-js" */),
  "component---src-pages-services-extrusions-js": () => import("./../../../src/pages/services/extrusions.js" /* webpackChunkName: "component---src-pages-services-extrusions-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-induction-site-services-js": () => import("./../../../src/pages/services/induction-site-services.js" /* webpackChunkName: "component---src-pages-services-induction-site-services-js" */),
  "component---src-pages-services-special-services-js": () => import("./../../../src/pages/services/special-services.js" /* webpackChunkName: "component---src-pages-services-special-services-js" */),
  "component---src-pages-technology-and-quality-assurance-js": () => import("./../../../src/pages/technology-and-quality-assurance.js" /* webpackChunkName: "component---src-pages-technology-and-quality-assurance-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

